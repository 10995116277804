<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/clientdetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }} </span>
        </router-link>
        <router-link :to="'/client-update/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.dropdown-menu {
  z-index: 80;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
export default {
  name: 'dropdown-4',
  props: {
    allUsers: Array,
    btnClass: String,
    index: Object,
    id: Number
  },

  components: {},
  mounted() {}
}
</script>
