<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="allUsers"
      loading-text="Malumot yuklanmoqda..."
      single-select
      @click:row="rowClick"
      item-key="id"
      no-data-text="Malumot kiritilmagan"
      hide-default-footer
      class="row-pointer"
      @page-count="pageCount = $event"
    >
      <template slot="headerCell" slot-scope="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ props }}
            </span>
          </template>
          <span>
            {{ props.header }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.index`]="{ index }">
        {{ index }}
      </template>
      <template late v-slot:[`item.status.status`]="{ item }">
        <b-alert
          show
          pill
          :variant="item.status_class"
          class="mb-0 p-0 text-center"
          >{{ item.status.status }}</b-alert
        >
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <Dropdown4 :index="item"/>
      </template>
    </v-data-table>
    <div class="text-center py-2">
      <v-pagination
        v-model="page"
        :total-visible="7"
        @input="getPostData"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import Dropdown4 from '@/view/content/dropdown/Dropdown4.vue'
export default {
  data: () => ({
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    perPage: 10,
    page: 1,
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: true,
    fields: [
      {
        label: '#',
        key: 'index'
      },
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Nomi',
        key: 'full_name'
      },
      {
        label: 'INN',
        key: 'inn'
      },
      {
        label: 'OKED',
        key: 'oked',
        _rowVariant: 'danger'
      },
      {
        label: 'Oblast',
        key: 'adress_oblast.name'
      },
      {
        label: 'Turi',
        key: 'juridical_type'
      },
      {
        label: 'Status',
        key: 'status.status',

        sortable: true,
        sortByFormatted: true,
        filterByFormatted: true
      },

      {
        key: 'actions',
        label: '',
        sortable: false
      }
    ]
  }),
  components: {
    Dropdown4
  },
  created() {
    this.page = parseInt(this.$route.params.id, 10)
    this.getPostData(this.page)
  },
  watch: {
    '$route.params.id': function (id) {
      this.page = parseInt(id, 10)
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('TABLE_HEADER.CLIENT'), value: 'full_name' },
        { text: this.$t('TABLE_HEADER.INN'), value: 'inn' },
        { text: this.$t('TABLE_HEADER.OKED'), value: 'oked' },
        { text: this.$t('TABLE_HEADER.PROVINCE'), value: 'adress_oblast.name' },
        { text: this.$t('TABLE_HEADER.TYPE'), value: 'juridical_type' },
        { text: this.$t('TABLE_HEADER.STATUS'), value: 'status.status' },
        { text: '', value: 'action', sortable: false }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    allUsers() {
      return this.$store.state.requests.allUsers.results
    },
    pageCount() {
      var data
      if (this.$store.state.requests.allUsers.count !== undefined) {
        data = Math.ceil(this.$store.state.requests.allUsers.count / 10)
      } else {
        data = 0
      }
      return data
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)

        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
    allstatus() {
      return this.fields

        .filter((f) => f.sortable)

        .map((f) => {
          return { text: f.label, value: f.key }
        })
    }
  },
  methods: {
    rowClick: function (item, row) {
      let formattedName = item?.full_name.replace(/^"([^"]*)"(.*)$/, '$1')
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item?.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push({ name: "ClientCabinetForZayavkas", query: {name: formattedName} })
        row.select(false)
      }
    },
    getPostData() {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: this.page,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('clientsSearchPerPages', asd)
        if (this.$route.path !== '/allUsers/' + this.page) {
          this.$router.push('/allUsers/' + this.page)
        }
      } else {
        this.$store.dispatch('getAllUsersPerPage', this.page)
        if (this.$route.path !== '/allUsers/' + this.page) {
          this.$router.push('/allUsers/' + this.page)
        }
      }
    }
  }
}
</script>
<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
